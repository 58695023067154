<template>
  <div v-if="photoDetail" class="photoDetail text-muted">
    <div
      class="bg-light d-flex align-items-center border-radius border p-3 my-3"
      style="cursor: pointer"
      @click="photoDialog = true"
    >
      <img
        class="border-radius me-3"
        :class="{ blur: preventAccess }"
        :src="photoDetail.TruckTrailerImage"
        alt=""
      />
      <div>
        <p>Çekici Kafa Fotografı</p>
        <span>Fotografı büyültmek için üzerine tıklayınız</span>
      </div>
    </div>

    <div
      class="bg-light d-flex align-items-center border-radius border p-3 my-3"
      style="cursor: pointer"
      @click="photoDialog2 = true"
    >
      <img
        class="border-radius me-3"
        :class="{ blur: preventAccess }"
        :src="photoDetail.TrailerImage"
        alt=""
      />
      <div>
        <p>Dorse Fotoğrafı</p>
        <span>Fotografı büyültmek için üzerine tıklayınız</span>
      </div>
    </div>

    <div
      class="bg-light d-flex align-items-center border-radius border p-3 my-3"
      style="cursor: pointer"
      @click="photoDialog3 = true"
    >
      <img
        class="border-radius me-3"
        :class="{ blur: preventAccess }"
        :src="photoDetail.DriverImage"
        alt=""
      />
      <div>
        <p>Şoför Fotoğrafı</p>
        <span>Fotografı büyültmek için üzerine tıklayınız</span>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      class="sm-modal"
      :visible.sync="photoDialog"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <div class="d-flex align-items-center justify-content-center">
        <img
          class="w-100 border-radius"
          :class="{ blur: preventAccess }"
          :src="photoDetail.TruckTrailerImage"
          alt=""
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="px-5 py-3" @click="handleClose">Kapat</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      class="sm-modal"
      :visible.sync="photoDialog2"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <div class="d-flex align-items-center justify-content-center">
        <img
          class="w-100 border-radius"
          :class="{ blur: preventAccess }"
          :src="photoDetail.TrailerImage"
          alt=""
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="px-5 py-3" @click="handleClose">Kapat</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      class="sm-modal"
      :visible.sync="photoDialog3"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <div class="d-flex align-items-center justify-content-center">
        <img
          class="w-100 border-radius"
          :class="{ blur: preventAccess }"
          :src="photoDetail.DriverImage"
          alt=""
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="px-5 py-3" @click="handleClose">Kapat</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["photoDetail", "preventAccess"],
  data() {
    return {
      photoDialog: false,
      photoDialog2: false,
      photoDialog3: false,
    };
  },
  methods: {
    handleClose() {
      this.photoDialog = false;
      this.photoDialog2 = false;
      this.photoDialog3 = false;
      this.$(".v-modal")[0].remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.photoDetail {
  .bg-light {
    img {
      width: 100px;
    }
  }
}
</style>