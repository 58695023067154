<template>
  <div  class="shipmentDetail">
    <div class="row">
      <div class="col-md-12 mt-2 d-flex align-items-baseline gap-1">
        <img src="../../assets/icons/nkicon/locationone.svg" alt="" />
        <span> Yükleme </span>
      </div>
      <div class="col-md-12">
        <div
          class="d-flex align-items-start flex-column text-left"
          style="margin-left: 15px"
        >
          <!-- <el-button
            class="map-btn"
            :class="{
              blur: data.preventAccess,
              'cursor-location': data.preventAccess,
            }"
            @click="handleWarehouseMapDialog"
            type="text"
            >Konumu Görüntüle</el-button
          > -->
          <span style="font-size: 20px">
            {{ data.warehouseDistrict }}
            | {{ data.warehouseCity }}
          </span>
        </div>
      </div>

      <div class="col-md-12">
        <div class="detailDivider"></div>
      </div>

      <div class="col-md-12 mt-2 d-flex align-items-baseline gap-1">
        <img src="../../assets/icons/nkicon/locationtwo.svg" alt="" />
        <span> İndirme </span>
      </div>
      <div class="col-md-12">
        <div
          class="d-flex align-items-start flex-column text-left"
          style="margin-left: 15px"
        >
          <!-- <el-button
            class="map-btn"
            :class="{
              blur: data.preventAccess,
              'cursor-location': data.preventAccess,
            }"
            type="text"
            @click="handleCompanyMapDialog"
            >Konumu Görüntüle</el-button
          > -->
          <span style="font-size: 20px;">
            {{ data.deliveryCompanyCity }}
            | {{ data.deliveryCompanyDistrict }}
          </span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="detailDivider"></div>
      </div>
      <div class="col-md-12 mt-2 d-flex align-items-baseline gap-1">
        <img src="../../assets/icons/nkicon/cargo.svg" alt="" />
        <span> Yük </span>
      </div>
      <div class="col-md-12">
        <div
          class="d-flex align-items-start flex-column text-left"
          style="margin-left: 15px"
        >
          <!-- <el-button
            class="map-btn"
            :class="{
              blur: data.preventAccess,
              'cursor-location': data.preventAccess,
            }"
            type="text"
            @click="handleCompanyMapDialog"
            >Konumu Görüntüle</el-button
          > -->
          <span style="font-size: 20px;">
            {{ data.productType }}
          </span>
        </div>
      </div>
      <!-- <div class="col-md-12">
        <div class="detailDivider"></div>
      </div>
      <div class="col-md-12">
        <div v-if="data.contactName"><b>İletişime Geçilecek Kişi:</b> <br class="d-block d-sm-none"> {{data.contactName}} </div>
        <div v-if="data.contactNumber"><b>İletişime Geçilecek Numara:</b> <br class="d-block d-sm-none"> {{data.contactNumber}} </div>
      </div> -->
    </div>
    <!-- <el-dialog
      :close-on-click-modal="false"
      title="Konum"
      :visible.sync="warehouseMapDialog"
      :modal-append-to-body="false"
    >
      <GmapMap
        :center="{
          lat: data.warehouse.Location
            ? data.warehouse.Location.Coordinates[1]
            : 0,
          lng: data.warehouse.Location
            ? data.warehouse.Location.Coordinates[0]
            : 0,
        }"
        :zoom="14"
        style="width: 100%; height: 400px"
        ref="mapRef"
      >
        <GmapMarker
          :position="{
            lat: data.warehouse.Location
              ? data.warehouse.Location.Coordinates[1]
              : 0,
            lng: data.warehouse.Location
              ? data.warehouse.Location.Coordinates[0]
              : 0,
          }"
        />
      </GmapMap>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleWarehouseMapDialog">Kapat</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="Konum"
      :visible.sync="companyMapDialog"
      :modal-append-to-body="false"
    >
      <GmapMap
        :center="{
          lat: data.deliveryCompany.Location
            ? data.deliveryCompany.Location.Coordinates[1]
            : 0,
          lng: data.deliveryCompany.Location
            ? data.deliveryCompany.Location.Coordinates[0]
            : 0,
        }"
        :zoom="13"
        style="width: 100%; height: 400px"
        ref="mapRef"
      >
        <GmapMarker
          :position="{
            lat: data.deliveryCompany.Location
              ? data.deliveryCompany.Location.Coordinates[1]
              : 0,
            lng: data.deliveryCompany.Location
              ? data.deliveryCompany.Location.Coordinates[0]
              : 0,
          }"
        />
      </GmapMap>
      <span slot="footer" class="dialog-footer">
        <el-button @click="companyMapDialog = false">Kapat</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  props: ["data", "nativeMapDialog"],
  data() {
    return {
      warehouseMapDialog: false,
      companyMapDialog: false,
    };
  },
  created() {
    if (!this.data) {
      this.data = {};
    }
  },
  computed: {
    user() {
      return this.$store.getters["userState"];
    },
  },
  methods: {
    handleWarehouseMapDialog() {
      if (!this.data.preventAccess) {
        if (this.nativeMapDialog) {
          const warehouseMapDialog =
            this.$store.getters["nativeWHMapDialog"].warehouseMapDialog;
          this.$store.commit("nativeWHMapDialog", {
            lat: this.data.warehouse.Location?.Coordinates[1],
            lng: this.data.warehouse.Location?.Coordinates[0],
            warehouseMapDialog: !warehouseMapDialog,
          });
        } else {
          this.warehouseMapDialog = !this.warehouseMapDialog;
        }
      }
    },
    handleCompanyMapDialog() {
      if (!this.data.preventAccess) {
        if (this.nativeMapDialog) {
          const companyMapDialog =
            this.$store.getters["nativeCMapDialog"].companyMapDialog;
          this.$store.commit("nativeCMapDialog", {
            lat: this.data.deliveryCompany.Location?.Coordinates[1],
            lng: this.data.deliveryCompany.Location?.Coordinates[0],
            companyMapDialog: !companyMapDialog,
          });
        } else {
          this.companyMapDialog = !this.companyMapDialog;
        }
      }
    },
  },
};
</script>

<style scoped>
.shipmentDetail {
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
}
.detailDivider {
  border-bottom: 1px dashed white;
  margin: 15px 0;
}
.detailSection {
  background-color: #2d405a;
  border-radius: 16px;
  padding: 10px 15px;
}
.map-btn {
  background-color: transparent;
}
.cursor-location {
  cursor: default;
}
</style>
