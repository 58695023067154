<template>
  <el-col :span="24">
    <el-row class="carrierContainer p-3 rounded">
      <el-col :span="vehicleInfo.vehicleName ? 12 : 24" class="carrierBox rounded mb-3 p-4 d-flex flex-column">
        <h4 class="m-1">
          Araç Tipi:
          <small>{{ vehicleInfo.vehicleName ? vehicleInfo.vehicleName : "Tır, Kamyon veya Kamyonet" }}</small>
        </h4>
      </el-col>
      <el-col :span="12" v-if="vehicleInfo.vehicleName" class="carrierBox rounded mb-3 ml-1 p-3 d-flex flex-column">
        <img v-if="vehicleInfo.vehicleName === 'Kamyon'" src="@/assets/images/kamyon.svg" alt="" class="align-self-center" style="max-width: 170px" />
        <img v-else-if="vehicleInfo.vehicleName === 'Tır'" src="@/assets/images/tir.svg" alt="" class="align-self-center" style="max-width: 180px" />
        <img v-else src="@/assets/images/kamyonet-kasali.svg" alt="" class="align-self-center" style="max-width: 180px" />
      </el-col>
      <el-col :span="24" class="carrierBox rounded mb-3 p-3 d-flex flex-column">
        <h4 class="m-1">
          Ağırlık/Araç:
          <span>{{ vehicleInfo.productWeight ? vehicleInfo.productWeight + "kg" : vehicleInfo.Vehicle ? vehicleInfo.Vehicle.TotalWeight + "kg" : null }} </span>
        </h4>
      </el-col>
      <!-- <el-col :span="24" class="carrierBox rounded p-3 d-flex flex-column">
        <h4 class="m-1">
          Dorse Cinsi:
          <span>{{ getTrailerTypes() ? getTrailerTypes() : "Tır, Kamyon veya Kamyonet" }}</span>
        </h4>
      </el-col> -->
      <el-col v-if="vehicleInfo.details" :span="24" class="carrierBox rounded mb-3 p-3 d-flex flex-column">
        <h4 class="m-1">
          Detaylar:
          <span>{{ vehicleInfo.details }} </span>
        </h4>
      </el-col>
    </el-row>
  </el-col>
</template>

<script>
export default {
  props: ["vehicleInfo"],
  updated() {
    console.log("vehicleInfo:", this.vehicleInfo);
  },
  methods: {
    filteredTrailerTypes() {
      return this.trailerTypes.filter((item) => item);
    },
    // getTrailerTypes() {
    //   let trailerNames = this.trailers?.filter((trailer) => this.vehicleInfo.trailerId?.find((vehicleTrailerId) => vehicleTrailerId == trailer?.ID)).map((filteredTrailer) => filteredTrailer.Title);
    //   if (trailerNames.length !== 0)
    //     return trailerNames
    //       .toString()
    //       .split(",")
    //       .join(", ");
    //   else
    //     return this.trailers
    //       ?.filter((trailer) => trailer.ID === this.vehicleInfo.Vehicle.TrailerId)
    //       .map((filteredTrailer) => filteredTrailer.Title)
    //       .toString()
    //       .split(",")
    //       .join(", ");
    // },
  },
  computed: {
    trailers: {
      get() {
        return this.$store.getters["trailerDetails"];
      },
    },
  },
};
</script>
