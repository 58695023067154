<template>
  <el-row v-if="driverInfo" class="text-white mb-4 carrierContainer rounded-4 p-3 d-flex align-items-center" style="min-height: 100px;">
    <!-- <span>{{ driverInfo }}</span> -->
    <el-col v-if="driverInfo.DriverImage" :span="8" class="position-relative">
      <img
        :src="driverInfo.DriverImage"
        alt=""
        class="rounded-4"
        :class="{ blur: preventAccess }"
        style="max-width: 100% !important"
      />
    </el-col>
    <el-col
      :span="16"
      class="d-flex flex-column"
      :class="driverInfo.DriverImage ? 'ps-2' : 'text-center w-100 me-3'"
    >
      <span v-if="!driverInfo.DriverName" class="fw-bold">Şoför Puanı</span>
      <span
        ><img
          src="../../assets/icons/nkicon/star.svg"
          alt=""
          style="max-width: 100% !important"
        />
        {{ rate  }}</span
      >
      <h4 v-if="driverInfo.DriverName">
        {{
          preventAccess
            ? driverInfo.DriverName.substring(0, 1) +
              Array(driverInfo.DriverName.length).fill("*").join("")
            : driverInfo.DriverName
        }}
      </h4>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: ["driverInfo", "preventAccess", "rate"],
};
</script>

<style scoped>
.blur-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 150px;
  height: 100%;
  border: none;
  background-color: black;
  opacity: 0.3;
}
.carrierImage {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.carrierContainer {
  background-color: #4d668a;
}
.carrierBox {
  background-color: #2d405a;
  color: white;
}
</style>
