<template>
  <div>
    <div class="row mb-3" v-if="bid">
      <div class="offersSection">
        <div class="col-md-8 col-9 offers">
          <div class="row">
            <div class="col-4 offerImage">
              <img src="../../assets/icons/nkicon/gidenteklif.svg" alt="" />
            </div>
            <div class="col-8 offerDetail">
              <span> {{ $moment(bid.CreatedDateTime).format("LLL") }} </span>
              <span> Sizin Teklifiniz </span>
              <!-- <span> Ağırlık: {{ advertInfo.ProductWeight }} kg </span>
              <span> Birim Fiyatı: {{ bid.Price.toFixed(2) }} ₺ </span> -->
              <div class="col-md-12">
                <div class="detailDivider"></div>
              </div>
              <span style="font-size: 20px"> Toplam: {{ bid.Price }} ₺</span>
            </div>
          </div>
        </div>
      </div>
      <div class="offersSection left mt-2">
        <div class="col-md-8 col-9 offers" v-if="bid.Price2">
          <div class="row">
            <div class="col-4 offerImage d-flex flex-column justify-content-center">
              <img src="../../assets/icons/nkicon/gelenteklif.svg" alt="" />
            </div>
            <div class="col-8 offerDetail">
              <span> {{ $moment(bid.Price2Date).format("LLL") }} </span>
              <span> Tedarikçi Firma Teklifi </span>
              <!-- <span> Ağırlık: {{ advertInfo.ProductWeight }} kg </span>
              <span> Birim Fiyatı: {{ bid.Price2.toFixed(2) }} ₺ </span> -->
              <div class="col-md-12">
                <div class="detailDivider"></div>
              </div>
              <span style="font-size: 20px"> Toplam: {{ bid.Price2 }} ₺</span>
            </div>
          </div>
        </div>
      </div>
      <div class="offersSection mt-2">
        <div class="col-md-8 col-9 offers" v-if="bid.Price3">
          <div class="row">
            <div class="col-4 offerImage">
              <img src="../../assets/icons/nkicon/gidenteklif.svg" alt="" />
            </div>
            <div class="col-8 offerDetail">
              <span>{{ $moment(bid.Price3Date).format("LLL") }} </span>
              <span> Sizin Teklifiniz </span>
              <!-- <span> Ağırlık: {{ advertInfo.ProductWeight }} kg </span>
              <span> Birim Fiyatı: {{ bid.Price3.toFixed(2) }} ₺ </span> -->
              <div class="col-md-12">
                <div class="detailDivider"></div>
              </div>
              <span style="font-size: 20px"> Toplam: {{ bid.Price3 }} ₺</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center">
        <img src="../../assets/images/empty_offer.svg" alt="" />
        <p>Henüz bir teklifiniz bulunmamaktadır.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bid: Object,
    advertInfo: Object,
  },
  computed: {
    loading() {
      return this.$store.getters["loading"];
    },
  },
};
</script>

<style lang="scss" scoped>
.detailDivider {
  border-bottom: 1px solid white;
  margin: 15px 0;
}
.offers {
  background-color: #4d668a;
  border-radius: 16px;
}
.left {
  justify-content: flex-start !important;
  .row {
    flex-direction: row-reverse;
    .offerImage {
      justify-content: flex-end;
    }
    .offerDetail {
      align-items: flex-start !important;
    }
  }
}
.offersSection {
  display: flex;
  justify-content: flex-end;
}
.offers {
  padding: 20px 30px;
  .row {
    height: 100%;
    .offerImage {
      display: flex;
      align-items: center;
      img {
        width: 25%;
      }
    }
    .offerDetail {
      height: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
    span {
      margin-bottom: 15px;
    }
    span:nth-child(2) {
      font-weight: bold;
      font-size: 25px !important;
    }
    span:nth-child(3) {
      font-size: 25px !important;
    }
  }
  .confirm-second {
    cursor: pointer;
  }
}
@media (max-width: 767.9px) {
  .offersSection {
    .offerImage img {
      width: 100% !important;
    }
    .offerDetail {
      span:nth-child(2) {
        font-size: 15px !important;
      }
      span:nth-child(3) {
        margin-bottom: 0 !important;
      }
    }
  }
}
@media (max-width: 380px) {
  .offerDetail {
    padding: 0;
    span {
      margin-bottom: 5px !important;
    }
  }
}
</style>
