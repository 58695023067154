<template>
  <div v-if="personalInfo">
    <el-row>
      <el-row v-if="isDataHidden" class="blur-text text-center mt-0 w-100 h-100">
        <el-col class="h-100">
          <div 
            class="w-100 h-100 d-flex align-items-center justify-content-center px-2"               
            @click="
              if ($store.state.User.token) {
                infoPaymentDialog = true;
              } else {
                $store.commit('toggleLoginDialog', true);
              }
            "
            style="cursor: pointer;"
          >
            <a
              class="text-white px-2"
              href="javascript:;"
              style="width: 360px;"
            >
              <span v-if="personalInfo.Supplier && user.IsCarrier">
                Tedarikçi iletişim bilgisine hemen erişmek için {{ nakuAmount }}x
                <img style="width: 50px" src="../../assets/images/naku.svg" alt="" />
                ödeyiniz.
              </span>

              <span v-if="personalInfo.Supplier && !user.IsCarrier">
                Nakliyeci iletişim bilgisine hemen erişmek için {{ nakuAmount }}x
                <img style="width: 50px" src="../../assets/images/naku.svg" alt="" />
                ödeyiniz.
              </span>
            </a>
          </div>
        </el-col>
      </el-row>
      <el-col class="position-relative" :class="{ blur: isDataHidden }">
        <el-row class="carrierContainer p-3 rounded-4">
          <el-col class="carrierBox mb-2 rounded p-3 d-flex flex-column">
            <h4>Adı Soyadı:</h4>
            <span v-if="user.IsCarrier">{{ personalInfo.Supplier ? personalInfo.ContactName : "*****" }}</span>
            <span v-else>{{ personalInfo.Supplier ? personalInfo.User.Title : "*****" }}</span>

          </el-col>
          <el-col :span="24" class="carrierBox mb-2 rounded p-3 d-flex flex-column">
            <h4>Telefon:</h4>
            <span v-if="user.IsCarrier">{{ personalInfo.Supplier ? personalInfo.ContactNumber: "*****" }}</span>
            <span v-else>{{ personalInfo.Supplier ? personalInfo.User.PhoneNumber : "*****" }}</span>

          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-dialog :close-on-click-modal="false" class="sm-modal" :title="user.IsCarrier ? 'Tedarikçi Bilgilerini Görüntüle' : 'Nakliyeci Bilgilerini Görüntüle'" :visible.sync="infoPaymentDialog">
      <p class="text-white">Bu işlem karşılığında hesabınızdan {{ nakuAmount }}x NAKU kullanılacaktır. Bu işlemi onaylıyor musunuz?</p>
      <div class="d-flex justify-content-center align-items-center text-white">
        <span class="fs-20 fw-bold"> {{ nakuAmount }}x</span>
        <img src="../../assets/images/naku.svg" alt="" style="width: 120px" />
      </div>
      <span slot="footer" class="dialog-footer d-flex">
        <el-button class="bg-transparent px-5 py-3" @click="infoPaymentDialog = false">İptal</el-button>
        <el-button class="pay-button" type="primary" @click="payNakuForDetails"
          ><span class="fw-bold"> {{ nakuAmount }}x</span>
          <img src="../../assets/images/naku.svg" alt="" style="width: 20px" />
          Öde ve Görüntüle
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["personalInfo", "nakuAmount", "adId"],
  data() {
    return {
      infoPaymentDialog: false,
    };
  },
  methods: {
    async payNakuForDetails() {
      const result = await this.$store.dispatch("payNakuForDetails", {
        id: { Id: this.adId },
        advertId: { AdvertId: this.adId },
      });
      if (!result.HasError) {
        this.infoPaymentDialog = false;
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters["userState"];
    },
    paidAdverts() {
      if (this.user) {
        return this.user.PaidAdverts || [];
      }
      return [];
    },
    isDataHidden() {
      return !this.paidAdverts.some(item => item.AdvertId === this.adId);
    }
  }
};
</script>

<style scoped>
.pay-button {
  white-space: normal !important;
  word-wrap: break-word;
}
.blured-row {
  filter: blur(4px);
}
.shipmentDetail {
  position: relative;
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.detailDivider {
  border-bottom: 1px dashed white;
  margin: 15px 0;
}
.detailSection {
  background-color: #2d405a;
  border-radius: 16px;
  padding: 10px 15px;
}
.blur-text {
  position: absolute;
  z-index: 1;
}
.carrierContainer {
  background-color: #4d668a;
}
.carrierBox {
  background-color: #2d405a;
  color: white;
}
</style>
